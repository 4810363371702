.about-container {
  display: grid;
  grid-template-areas: 
    "nav nav"
    "header header"
    "content pictures"
    "footer footer";
  grid-template-rows: 64px .3fr 2.6fr 214px;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 50px;
  grid-column-gap: 100px;
  height: 100vh;
  width: 100vw;
}

.about-header {
  grid-area: header;
  text-align: center;
  font-weight: 500;
  font-size: 3rem;
  margin: auto;
}

.about-content {
  grid-area: content;
  max-width: 500px;
  font-weight: 300;
  padding-left: 10%;
  font-size: 1.10rem;
  align-self: top;
  justify-self: right;
}

.about-section-header {
  font-weight: 500;
}

.about-pictures {
  grid-area: pictures;
  align-self: top;
  padding-top: 1rem;
}

.profile-pic {
  max-width: 450px;
  border: 5px solid white;
}

@media (max-width: 1200px) { /* Medium Screens */
  .profile-pic {
    max-width: 80%;
  }
}

@media (max-width: 880px) { /* Medium Screens  */
  .about-container {
    grid-template-areas: 
      "nav"
      "header"
      "pictures"
      "content"
      "footer";
    grid-template-rows: 64px .3fr 2.6fr 1fr .3fr;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  .about-pictures {
    text-align: center;
  }

  .about-content {
    font-weight: 300;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.10rem;
    justify-self: center;
  }

  .profile-pic {
    max-width: 350px;
  }
}

@media (max-width: 410px) { /* Small Screens  */
  .profile-pic {
    max-width: 80%;
  }
}