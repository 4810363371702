.portfolio-container {
  display: grid;
  grid-template-areas: 
    "nav nav"
    "sidebar portfolio-content-wrapper"
    "footer footer";
  grid-template-rows: 64px 1fr 214px;
  grid-template-columns: 350px calc(100vw - 350px);
  grid-row-gap: 1rem;
  grid-column-gap: 0px;
  height: 100vh;
  width: 100vw;
  flex-wrap: wrap;
}

@media (max-width: 1000px) {
  .portfolio-container {
    display: grid;
    grid-template-areas: 
      "nav"
      "portfolio-content-wrapper"
      "footer";
    grid-template-rows: 64px 1fr 214px;
    grid-template-columns: 100vw;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}

li {
  max-width: 85%;
}

.portfolio-content-wrapper {
  grid-area: portfolio-content-wrapper;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1.6;
  width: 90%;
  padding-right: 10%;
}

@media (max-width: 1000px) {
  .portfolio-content-wrapper {
    grid-area: portfolio-content-wrapper;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.6;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }  
}
@media (max-width: 600px) {
  .portfolio-content-wrapper {
    grid-area: portfolio-content-wrapper;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 2.5%;
    padding-right: 2.5%;
    line-height: 1.6;
    width: 95%;
  }  
}

/* PORTFOLIO INTRO */

.portfolio-header-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  margin-bottom: 10px;
}

@media (max-width: 725px) {
  .portfolio-header-container {
    flex-direction: column;
  }
}

.portfolio-header {
  font-weight: 400;
  font-size: 4rem;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.project-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.project-link:hover {
  color:rgb(199, 199, 199)
}

.cwf-logo-portfolio {
  width: 125px;
}

@media (max-width: 600px) {
  .cwf-logo-portfolio {
    width: 150px;
  }
}

.portfolio-content {
  font-size: 1.25rem;
  font-weight: 300;
  width: 100%;
}

@media (max-width: 680px) {
  .portfolio-header-container {
    flex-direction: column;
  }

  .portfolio-header {
    font-weight: 500;
    font-size: 2.5rem;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
  }
}

/* SECTION STYLES */
.version-wrapper {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 10px;
}

.version-header-container {
  display: flex;
  flex-direction: row;
}

.version-header {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0px;
  margin-right: 10px;
}

.version-header-title {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0px;
}

.version-divider {
  border-top: 1px;
  margin-right: 10%;
}

.section-header {
  font-size: 2rem;
  font-weight: 400;
}

.section-header-2 {
  font-size: 1.5rem;
  font-weight: 500;
}

.section-header-3 {
  font-size: 1.35rem;
  font-weight: 400;
}

.mockup-pic {
  width: 100%;
}

.yt-vid {
  aspect-ratio: 16 / 9;
  width: 100%;
}

@media (max-width: 650px) {
  .yt-vid {
    width: 90%;
    max-width: 800px;
  }
}
/*
  Add Breakpoint for 1000px for Tablet then smaller for mobile
*/

.code-snippet {
  max-width: 100%;
}

@media (max-width: 700px) {
  .code-snippet {
    max-width: 90%;
  }
}


